.page-title {
  @apply text-2xl font-bold text-dark-blue dark:text-white font-overpass;
}

.section-title {
  @apply text-lg mt-4 text-dark-blue dark:text-white font-overpass;
}

li.emptymessage {
  @apply !px-4;
}

.error {
  @apply text-red-500;
}

.select-none.error {
  @apply border border-solid border-red-500;
}

input.error {
  @apply border border-solid border-red-500;
}

textarea.error {
  @apply border border-solid border-red-500;
}

.field-box {
  @apply flex flex-col items-stretch;
}

.data-cell {
  @apply pt-1 pb-1;
}

.table-condensed td,
.table-condensed th {
  @apply !pt-1 !pb-1;
}

a,
button {
  @apply cursor-pointer;
  outline: none !important;
  box-shadow: none !important;
}

.gm-background {
  background: linear-gradient(
      90deg,
      #0072ce -13.89%,
      #0182d1 -1.73%,
      #0290d4 8.36%,
      #03a1d7 21.4%,
      #04abd9 40.98%,
      #04b6db 55.22%,
      #05c3dd 67.67%,
      #11cfe9 79.54%,
      #1ddcf7 91.99%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

.datatable-action-btn {
  @apply inline-flex items-center justify-center overflow-hidden relative text-left cursor-pointer select-none w-8 h-8 border-0 rounded-[50%] transition duration-200;
}

.datatable-action-btn.default {
  @apply text-gray-950 border-transparent focus:outline-0 focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:hover:text-white hover:bg-gray-300/20 dark:text-white/70;
}

.cancel {
  @apply text-red-700 border-red-500 focus:outline-0 focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(254,219,191,1)] hover:text-red-700 hover:bg-red-300/20 dark:text-white/70;
}

.paginator-prev-page-button {
  @apply relative inline-flex items-center justify-center overflow-hidden leading-none border-0 text-gray-500 min-w-[3rem] h-12 m-[0.143rem] rounded-md transition duration-200 dark:text-neutral-10 dark:bg-gm-aqua-dark cursor-default pointer-events-none opacity-60;
}

span[data-pc-section='input'] {
  @apply !font-normal;
}

th.VS-name-col input.p-column-filter {
  max-width: 180px !important;
}

th.VS-ID-col input.p-column-filter {
  max-width: 60px !important;
}

th.small-filter .p-column-filter {
  max-width: 80px !important;
}

th.middle-filter .p-column-filter {
  max-width: 150px !important;
}

th[data-pc-section='headercell'] {
  @apply p-2 pt-0 pb-0 border-b-0 bg-transparent dark:bg-transparent;
}

th[data-pc-section='headercell']:first-child {
  @apply pl-0;
}

th[data-pc-section='headercell']:last-child {
  @apply pr-0;
}

th.one-col-editor-header {
  @apply w-12 overflow-x-hidden;
}

th.one-col-editor-header div[data-pc-section='headercontent'] {
  @apply w-[40px];
}

th.one-col-editor-header div[data-pc-section='headercontent'] {
  @apply w-[40px];
}

table.tyre-set-states tr[data-pc-section='rowexpansion'] td[data-pc-section='bodycell'] {
  @apply pt-0;
}

div[data-pc-name='paginator'] {
  @apply !bg-transparent;
}

div[data-pc-section='headercontent'] {
  @apply pl-1 min-h-full bg-transparent border-b-[2px] border-b-gm-primary-90 dark:border-neutral-10;
}

th.editor-header div[data-pc-section='headercontent'] {
  @apply border-b-0;
}

th.editor-header span[data-pc-section='headertitle']::after {
  @apply !border-b-0;
}

th.editor-header span[data-pc-section='headertitle']::after {
  content: 'placeholder';
  opacity: 0;
}

tr.table-row {
  @apply !py-0 bg-white dark:bg-neutral-90 even:bg-alt-gray dark:even:bg-neutral-80 hover:bg-gray-100 dark:hover:bg-gray-700;
}

td.id-cell {
  @apply text-dark-blue dark:text-light-blue text-base font-semibold;
}

td[data-pc-section='bodycell'] {
  @apply !border-b-0;
}

table.compact td[data-pc-section='bodycell'] {
  @apply !py-1;
}

td[data-pc-section='bodycell']:not(:first-child) {
  @apply !pl-3;
}

div[data-pc-section='filtercontainer'] input {
  @apply py-1;
}

svg[data-pc-section='filterclearicon'] {
  @apply !absolute right-[30px] top-[12px];
}

svg[data-pc-section='filtericon'] {
  @apply !absolute right-[8px] top-[20px];
}

li[data-pc-section='emptymessage'] {
  @apply !px-2;
}

.icon-button {
  @apply w-[28px] h-[28px] !p-0 !px-0 !py-0 flex items-center justify-center;
}

.dark-button {
  @apply bg-gray-600 text-white dark:bg-white dark:text-gray-600;
}

div[data-pc-name='checkbox'] div[data-pc-section='box'] {
  @apply !w-5 !h-5 !rounded-[4px] border;
}

div[data-pc-name='checkbox'] div[data-pc-section='box'][data-p-highlight='false'] {
  @apply border-gray-200 bg-transparent dark:bg-neutral-10;
}

div[data-pc-name='checkbox'] div[data-pc-section='box'][data-p-highlight='true'] {
  @apply dark:bg-gm-blue dark:border-gm-blue;
}

div[data-pc-name='checkbox'] div[data-pc-section='box'] svg {
  @apply !w-3 !h-3 !text-white;
}

div[data-pc-name='sidebar'] {
  @apply bg-dark-blue dark:bg-neutral-90 text-neutral-10;
}

div[data-pc-name='inputswitch'] span[data-pc-section='slider']::before {
  @apply bg-white dark:bg-neutral-90 text-neutral-10 w-4 h-4 translate-y-[2px];
}

div[data-p-highlight='false'] span[data-pc-section='slider']::before {
  @apply translate-x-[0px];
}

div[data-p-highlight='true'] span[data-pc-section='slider']::before {
  @apply translate-x-[22px];
}

.add-node-btn {
  @apply w-[250px] p-2 border-neutral-90 dark:border-neutral-50 border-dashed border rounded-md text-neutral-90 dark:text-neutral-50 bg-transparent;
}

.node {
  @apply p-2 border-none rounded-md text-neutral text-left;
}

.master-workflow-node {
  @apply !bg-dark-blue w-[260px];
}

.workflow-node {
  @apply !bg-gm-blue p-2 w-[240px];
}

.task-node {
  @apply !bg-gm-aqua-dark p-2 w-[220px];
}

.drag-overlay {
  @apply cursor-pointer p-2 border-none rounded-md text-neutral-50 bg-neutral-80 dark:bg-neutral-50 dark:text-neutral-80;
}

.trash-active {
  @apply bg-red-500 rounded-lg transition-colors duration-500;
}

.trash-active {
  svg {
    @apply fill-white transition-colors duration-500;
  }
}
